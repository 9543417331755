<template>
  <div class="DataCenter">
    <div class="DataCenter_body">
      <el-tabs v-model="activeName">
        <el-tab-pane label="问卷查询" name="first">
          <div class="DataCenter_head" v-if="activeName === 'first'">
            <span style="margin-right: 10px;">项目:</span>
            <!--            <el-select v-model="ProjectValue" placeholder="请选择" @change="ProjectChange">-->
            <!--              <el-option v-for="(item, index) in Project" :key="index" :label="item.Name" :value="item.Id"></el-option>-->
            <!--            </el-select>-->
            <Cascader :value.sync="ProjectValue" :options="Project" @MyChange="ProjectChange"
                      :multiple="true"></Cascader>
            <span style="margin-right: 10px;margin-left: 10px;">问卷:</span>
            <el-select v-model="Questionnairevalue" placeholder="请选择">
              <el-option v-for="(item, index) in CRFSurface" :key="index" :label="item.Name"
                         :value="item.Id"></el-option>
            </el-select>
            <!-- <span style="margin-left: 10px;">患者姓名:</span>
                    <el-input v-model="value" placeholder="请输入内容"></el-input>
                      <span style="margin-left: 10px;">录入时间:</span>
                        <el-date-picker
                        v-model="value"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
            </el-date-picker>-->
            <el-button @click="QuestionnaireQuery" type="primary" icon="el-icon-search" style="margin-left: 10px;">查询
            </el-button>
            <el-button type="primary" @click="CollectedExport">
              <i class="el-icon-download"></i>导出
            </el-button>
            <el-button @click="DeleteQuery" type="primary">
              <i class="el-icon-delete"></i>删除
            </el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="患者查询" name="second">
          <div class="DataCenter_head" v-if="activeName === 'second'">
            <span style="margin-right: 10px;">项目:</span>
            <!--            <el-select v-model="ProjectValue" placeholder="请选择" @change="ProjectChange">-->
            <!--              <el-option v-for="(item, index) in Project" :key="index" :label="item.Name" :value="item.Id"></el-option>-->
            <!--            </el-select>-->
            <Cascader :value.sync="ProjectValue" :options="Project" @MyChange="ProjectChange"
                      :multiple="true"></Cascader>
            <span style="margin-right: 10px;margin-left: 10px;">患者:</span>
            <!-- <el-select v-model="PatientValue" placeholder="请选择" @change="PatientChange" >
                        <el-option
                        v-for="(item,index) in PatientTable"
                        :key="index"
                        :label="item.Name"
                        :value="item.Id">
                        </el-option>
            </el-select>-->
            <el-input v-model="PatientValue" placeholder="请输入患者姓名"></el-input>
            <!-- <span style="margin-left: 10px;">患者姓名:</span>
                    <el-input v-model="value" placeholder="请输入内容"></el-input>
                      <span style="margin-left: 10px;">录入时间:</span>
                        <el-date-picker
                        v-model="value"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
            </el-date-picker>-->
            <el-button @click="PatientQuery" type="primary" icon="el-icon-search" style="margin-left: 10px;">查询
            </el-button>
            <el-button @click="PatientExport" type="primary">
              <i class="el-icon-download"></i>导出
            </el-button>
            <el-button @click="DeleteQuery" type="primary">
              <i class="el-icon-delete"></i>删除
            </el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="草稿箱" name="third">
          <div class="DataCenter_head" v-if="activeName === 'third'">
            <span style="margin-right: 10px;">项目:</span>
            <!--            <el-select v-model="ProjectValue" placeholder="请选择" @change="ProjectChange">-->
            <!--              <el-option v-for="(item, index) in Project" :key="index" :label="item.Name" :value="item.Id"></el-option>-->
            <!--            </el-select>-->
            <Cascader :value.sync="ProjectValue" :options="Project" @MyChange="ProjectChange"
                      :multiple="true"></Cascader>
            <span style="margin-right: 10px;margin-left: 10px;">问卷:</span>
            <el-select v-model="Questionnairevalue" placeholder="请选择">
              <el-option v-for="(item, index) in CRFSurface" :key="index" :label="item.Name"
                         :value="item.Id"></el-option>
            </el-select>
            <el-button @click="QuestionnaireQuery" type="primary" icon="el-icon-search" style="margin-left: 10px;">查询
            </el-button>
            <el-button type="primary" @click="CollectedExport">
              <i class="el-icon-download"></i>导出
            </el-button>
            <el-button @click="DeleteQuery('draft')" type="primary">
              <i class="el-icon-delete"></i>删除
            </el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="DataCenter_middle content">
        <el-table border :data="tableData" style="width: 100%;" height="65vh"
                  :header-cell-style="{ background: 'rgb(240, 247, 253)' }" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50">
          </el-table-column>
          <el-table-column v-if="admin == '00000000-0000-0000-0000-000000000000'" prop="OrganizationName"
                           label="组织名称"
                           min-width="80" align="center"></el-table-column>
          <el-table-column align="center" prop="QuestionnaireName" label="问卷名称" :sortable="true"
                           :sort-method="sortByQuestionnaireName" width="480"></el-table-column>
          <el-table-column align="center" prop="TargetName" :sortable="true" :sort-method="sortByName"
                           label="患者姓名"></el-table-column>
          <el-table-column align="center" prop="TargetTel" label="患者电话"></el-table-column>
          <!-- <el-table-column
                        align="center"
                        :prop="EndTime ? EndTime : BeginTime"
                        label="填写时间">
          </el-table-column>-->
          <el-table-column label="填写时间" :sortable="true" :sort-method="sortByCreateTime" align="center">
            <template slot-scope="scope">{{ scope.row.EndTime ? scope.row.EndTime : scope.row.BeginTime }}</template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <el-link @click="ViewQuestionnaire(scope.row)" :underline="false">
                <i class="el-icon-zoom-in"></i>查看/修改
              </el-link>
              <!-- <el-link @click="ModifyQuestionnaire(scope.row)"><i class="el-icon-zoom-in"></i>修改</el-link> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
                  @pagination="pagination"></Pagination>
    </div>
    <div>
      <el-dialog :visible.sync="dialogVisible" fullscreen @close="iframeDialogClose">
        <div>
          <iframe v-if="Url != '' && dialogVisible" :src="Url" frameborder="0" width="100%" height="880px" ref="myFrame"></iframe>
          <div v-if="dataRow && dataRow.VoiceFile" style="position: absolute; top: 10%;right: 10%;">
            <audio :src="dataRow.VoiceFile" controls preload="auto"></audio>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import derive from "../../api/derive";
import PatientCenter from "../../api/PatientCenter";
import questionnaire from "../../api/questionnaire";
import {local} from "../../utils/storage";
import Pagination from "../../components/Pagination/index.vue";
import Cascader from "@/components/Cascader.vue";
export default {
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
            ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition =
              this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  data() {
    return {
      admin: local.get("UserId"),
      Url: "",
      dialogVisible: false,
      activeName: "first",
      Project: [{Name: "全部", Id: "ALL", ParentId: null},],
      CRFSurface: [],
      PatientTable: [],
      ProjectValue: [["ALL"]],
      Questionnairevalue: "",
      PatientValue: "",
      tableData: [],
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      multipleSelection: [],
      //保存row数据
      dataRow: {},
    };
  },
  components: {
    Cascader,
    Pagination,
  },
  created() {
    this.ProjectList();
    this.ProjectChange([["ALL"]]);
    this.$route.query.name && (this.activeName = this.$route.query.name)
  },
  beforeRouteUpdate(to, from, next) {
    to.query.name && (this.activeName = to.query.name)
    next()
  },
  watch: {
    activeName(val) {
      this.ProjectList('not');
    }
  },
  methods: {
    //远程搜索
    remoteMethod(name) {
      console.log(name);
      // PatientCenter.GetPageListData();
    },
    loadmore() {
      let params = {
        key: "",
        page: 1,
        rows: 10,
      };
    },
    //通过文字进行排序
    sortByQuestionnaireName(obj1, obj2) {
      if (
          [obj1.QuestionnaireName, obj2.QuestionnaireName][0] ==
          [obj1.QuestionnaireName, obj2.QuestionnaireName].sort((a, b) =>
              a.localeCompare(b, "zh", {sensitivity: "accent"})
          )[0]
      ) {
        return -1;
      } else {
        return 1;
      }
    },
    //数字文字排序
    sortByFrq(obj1, obj2) {
      if (
          [obj1.Frq, obj2.Frq][0] ==
          [obj1.Frq, obj2.Frq].sort((a, b) => {
            var c = isFinite(a),
                d = isFinite(b);
            return (
                (c != d && d - c) ||
                (c && d
                    ? a - b
                    : a.localeCompare(b, "zh", {sensitivity: "accent"}))
            );
          })[0]
      ) {
        return -1;
      } else {
        return 1;
      }
    },
    sortByName(obj1, obj2) {
      if (
          [obj1.TargetName, obj2.TargetName][0] ==
          [obj1.TargetName, obj2.TargetName].sort((a, b) =>
              a.localeCompare(b, "zh", {sensitivity: "accent"})
          )[0]
      ) {
        return -1;
      } else {
        return 1;
      }
    },
    sortByCreateTime(obj1, obj2) {
      console.log(obj1, obj2);
    },
    DeleteQuery(type) {
      if (this.multipleSelection.length == 0) {
        // this.$message({
        //   type:' info',
        //   message:'请先选择需要删除的问卷！'
        // });
        alert("请先勾选需要删除的问卷！");
        return;
      }
      let parameter = [];
      this.multipleSelection.forEach((item) => {
        parameter.push(item.Id);
      });
      this.$confirm(
          "你确定要删除这些数据吗? 删除后无法恢复,请谨慎操作",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            console.log(parameter, "---------------");
            derive.DeleteFollowUpRecordsData(parameter).then((res) => {
              if (res.data.Status == 1) {
                this.ProjectList();
                this.$message({
                  type: "success",
                  message: type ? '草稿已删除' : res.data.Message,
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.data.Message,
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    handleSelectionChange(val) {
      console.log(val, "12333333333333333333333");
      this.multipleSelection = val;
    },
    downloadAudioStream(data, name) {
      // ArrayBuffer 转为 Blob
      const blob = new Blob([data], {type: "application/vnd.ms-excel"});
      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.setAttribute("href", objectUrl);
      a.setAttribute("target", "_blank");
      a.setAttribute("download", name + "." + "xls");
      a.click();
      URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    },
    CollectedExport() {
      const Parameter = {
        QueryType: "01",
        ProjectId: this.ProjectValue,
        ProjectQuestionnaireId: this.Questionnairevalue,
      };
      derive.CollectedExportData(Parameter).then((res) => {
        this.downloadAudioStream(res.data, "数据导出");
        console.log(res);
      });
    },
    PatientExport() {
      const Parameter = {
        QueryType: "00",
        ProjectId: this.ProjectValue,
        // ProjectPatientId:this.PatientValue
        Key: this.PatientValue,
      };
      derive.CollectedExportData(Parameter).then((res) => {
        this.downloadAudioStream(res.data, "数据导出");
        console.log(res);
      });
    },
    async ProjectList(type) {
      derive.ProjectListData().then((res) => {
        if (res.data.Status == 1) {
          if(!type){
            this.Project.push(...res.data.Data);
          }
          this.CollectedQuestionnaire("01", this.ProjectValue, "", "");
        } else {
          alert(res.data.Message);
        }
        console.log(res);
      });
    },
    CollectedQuestionnaire(QueryType, ProjectId, ProjectQuestionnaireId, ProjectPatientId, PatientValue) {
      if (ProjectId) ProjectId = this.flatProjectId(ProjectId)
      const Parameter = {
        Key: PatientValue,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
        QueryType: QueryType,
        ProjectId: ProjectId,
        ProjectQuestionnaireId: ProjectQuestionnaireId,
        ProjectPatientId: ProjectPatientId,
        Behavior: this.activeName === 'third' ? '01' : '00'
      };
      questionnaire.CollectedQuestionnaireData(Parameter).then((res) => {
        console.log(res, "问卷查出已收集问卷列表");
        if (res.data.Status == 1) {
          this.total = res.data.Data.total;
          this.tableData = res.data.Data.rows;
        } else {
          alert(res.data.Message);
        }
      });
    },
    pagination() {
      this.CollectedQuestionnaire("", this.ProjectValue, "", "");
    },
    //问卷查出已收集问卷列表
    QuestionnaireQuery() {
      this.CollectedQuestionnaire(
          "01",
          this.ProjectValue,
          this.Questionnairevalue
      );
    },
    //患者查出已收集问卷列表
    PatientQuery() {
      this.CollectedQuestionnaire(
          "00",
          this.ProjectValue,
          "",
          "",
          this.PatientValue
      );
    },
    //患者列表change
    PatientChange(e) {
      console.log(e, "eee");
    },
    //项目查出问卷列表,患者列表
    ProjectChange(e) {
      this.Questionnairevalue = "";
      this.PatientValue = "";
      e = this.flatProjectId(e)
      const ParameterProject = {
        ProjectId: e,
      };
      derive.CRFSurfaceData(ParameterProject).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.CRFSurface = res.data.Data;
        } else {
          alert(res.data.Message);
        }
      });
      const ParameterPatient = {
        projectId: e,
      };
      PatientCenter.NothingGetPageListData(ParameterPatient).then((res) => {
        if (res.data.Status == 1) {
          this.PatientTable = res.data.Data;
        } else {
          alert(res.data.Message);
        }
      });
    },
    flatProjectId(ProjectId) {
      let arr = []
      ProjectId.forEach(item => {
        arr.push(item.slice(-1)[0])
      })
      return arr
    },
    //查看问卷
    ViewQuestionnaire(row) {
      this.Url = "";
      this.dataRow = row;
      this.dialogVisible = true;
      this.Url = `${window.global_config.BASE_URL1}/#/PcIframe?qid=${row.CollectedQuestionnaireId}&fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&type=5&r=${Math.random() * 10}`;
      this.Url = "about:blank";
      setTimeout(() => {
        this.Url = `${window.global_config.BASE_URL1}/#/PcIframe?qid=${row.CollectedQuestionnaireId}&fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&type=5&r=${Math.random() * 10}`;
      }, 300);
    },
    iframeDialogClose() {
      this.ProjectList();
    }
    //修改问卷
    // ModifyQuestionnaire(row){
    //   console.log(row);
    // }
  },
};
</script>
<style lang="scss" scoped>
.DataCenter_head {
  ::v-deep .el-input {
    width: 300px;
  }
}

::v-deep .el-tabs__item {
  font-size: 18px;
}

::v-deep .el-dialog .el-dialog__header {
  margin-bottom: 0;
}

::v-deep .el-dialog__headerbtn {
  top: 4px;
  font-size: 22px;
}

::v-deep .el-dialog__body {
  padding: 0;
}

// ::v-deep .el-input__inner{
//   height: 30px;
//   line-height: 30px;
// }
// ::v-deep .el-input__icon{
//   line-height: 30px;
// }
.DataCenter {
  width: 100%;
  //height: calc(100% - 70px);
  min-width: 1440px;
  padding: 20px;
  box-sizing: border-box;
  // border-radius: 10px;
  overflow: hidden;

  .DataCenter_body {
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    //height: 100%;

    .DataCenter_head {
      margin-bottom: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
    }

    .DataCenter_middle {
      // height: 630px;
      // padding: 20px;
      box-sizing: border-box;
      margin-bottom: 10px;
      flex: 1;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .DataCenter {
    min-width: 0 !important;

    .DataCenter_head {
      ::v-deep .el-input--suffix {
        width: 140px;
      }
    }

    .DataCenter_middle {
      margin-bottom: 0 !important;
    }
  }
}
</style>
